@import url("https://fonts.googleapis.com/css2?family=Tangerine&display=swap");

.topnav {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--topnav-height);
}

.topnav__left-kop {
  position: relative;
  height: 50px;
  background-color: var(--second-bg);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.topnav__left-kop__image {
  width: 50px;
  height: 50px;
  border-radius: 30%;
  background-color: var(--main-bg);
  border: 2px solid rgb(0, 0, 255);
  overflow: hidden;
  margin-right: 10px;
}

.topnav__left-kop__image > img {
  width: 100%;
}

.topnav__left-kop__name {
  font-family: "Tangerine", serif;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 4px 4px 4px #aaa;
}

.topnav__right {
  display: flex;
  align-items: center;
}

.topnav__right-item ~ .topnav__right-item {
  margin-left: 30px;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 15px;
}

.notification-item:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
}

.notification-item-notallow {
  display: flex;
  align-items: center;
  padding: 15px;
}

notification-item-notallow:hover {
  color: white;
  background-color: var(--second-color);
}

.notification-item > i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.notification-item-notallow > i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.topnav__right-user {
  display: flex;
  align-items: center;
}

.topnav__right-user__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 12px solid rgb(0, 0, 255);
  overflow: hidden;
  margin-right: 10px;
}

.topnav__right-user__image > img {
  width: 100%;
}

.topnav__right-user__name {
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
}
