* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

body {
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    height: 100%;
    width: 100%;
}

.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    background: lime;
}

.bg-dw1 {
    background-image: url("../../images/dw1.jpg");
}

.bg-dw2 {
    background-image: url("../../images/dw2.jpg");
}

.bg-dw3 {
    background-image: url("../../images/dw3.jpg");
}

.bg-dw4 {
    background-image: url("../../images/dw4.jpg");
}

.bg-dw5 {
    background-image: url("../../images/dw5.jpg");
}

.bg-dw6 {
    background-image: url("../../images/dw6.jpg");
}

.bg-dw7 {
    background-image: url("../../images/dw7.jpg");
}

.bg-dw8 {
    background-image: url("../../images/dw8.jpg");
}

.bg-dw9 {
    background-image: url("../../images/dw9.jpg");
}

.bg-dw10 {
    background-image: url("../../images/dw10.jpg");
}

.bg-dw11 {
    background-image: url("../../images/dw11.jpg");
}

.bg-dw12 {
    background-image: url("../../images/dw12.jpg");
}

.bg-container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-control:focus {
    border-width: medium !important;
    border-color: #ffc400ec !important;
    box-shadow: 0 0 25px rgb(0, 255, 51) !important;
}

.glow {
    font-size: 18px;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 4px #fff, 0 0 6px #fff, 0 0 8px #e60073, 0 0 10px #e60073, 0 0 12px #e60073, 0 0 14px #e60073, 0 0 16px #e60073;
    }

    to {
        text-shadow: 0 0 6px #fff, 0 0 8px #ff4da6, 0 0 10px #ff4da6, 0 0 12px #ff4da6, 0 0 14px #ff4da6, 0 0 16px #ff4da6, 0 0 18px #ff4da6;
    }
}