.progress-container {
  width: 100%;
  margin: auto;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
}

.barmarker-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.barmarker {
  width: 100%;
  display: grid;
  place-content: center;
}

.barmarker:is(:not(:last-child)) {
  border-right: 1px solid #000;
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 32px;
  margin-top: 5px;
}

.progress::-webkit-progress-bar {
  background-color: lightcoral;
  border-radius: 10px;
}

.progress::-webkit-progress-value {
  background-color: limegreen;
  border-radius: 10px;
}

.progress::after {
  content: attr(value) '%';
  position: absolute;
  width: 56px;
  top: 55%;
  left: 50%;
  margin-left: -28px;
  font-weight: bold;
}