.dropdown-toggle::after {
    display: none;
}

.dropdown {
    position: relative;
    z-index: 99;
}

.dropdown__toggle {
    border: 0;
    outline: 0;
    background-color: transparent;
    position: relative;
}

.dropdown__toggle>i {
    font-size: 1.8rem;
    color: var(--txt-color);
}

.dropdown__toggle-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 3px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--main-color);
    color: var(--txt-white);
    font-size: 0.6rem;
}
